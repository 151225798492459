import React from 'react';
import {theme} from '../constants';
import {hooks} from '../hooks';
import {custom} from '../custom';
import {ProductType} from '../types';
import {actions} from '../store/actions';
import {utils} from '../utils';
import {Box, Grid, useTheme, useMediaQuery} from '@mui/material';

type Props = {item: ProductType};

const renderMinusSvg = () => (
  <svg width={10} height={10} fill='none'>
    {' '}
    {/* Reduced size */}
    <path
      stroke='#FFFFFF'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.2}
      d='M2 5h6'
    />
  </svg>
);

const renderPlusSvg = () => (
  <svg width={10} height={10} fill='none'>
    {' '}
    {/* Reduced size */}
    <path
      stroke='#FFFFFF'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.2}
      d='M5 2v6M2 5h6'
    />
  </svg>
);

export const ProductCounternew: React.FC<Props> = ({item}) => {
  const dispatch = hooks.useAppDispatch();
  const themeone = useTheme();
  const isMobile = useMediaQuery(themeone.breakpoints.down('sm'));
  const cart = hooks.useAppSelector((state) => state.cartSlice.list);

  const quantityInCart = (item: ProductType, cart: ProductType[]): number => {
    const ifItemInCart = cart.find((el) => el.id === item.id);
    const quantity =
      ifItemInCart && ifItemInCart.quantity ? ifItemInCart.quantity : 0;
    return quantity;
  };

  const quantity = quantityInCart(item, cart);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
      }}
    >
      <div
        style={{
          ...utils.rowCenter(),
          marginTop: 8,
          backgroundColor: theme.colors.mainColor,
          borderRadius: 8, // Reduced border-radius
          padding: '6px 10px', // Reduced padding for a smaller appearance
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <button
          onClick={(e) => {
            e.stopPropagation();
            dispatch(actions.removeFromCart(item));
          }}
          style={{
            // backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
            padding: '8px', // Reduced padding
            borderRadius: '50%',
            backgroundColor: theme.colors.mainColor,
          }}
        >
          {renderMinusSvg()}
        </button>

        <div
          style={{
            ...theme.fonts.DM_Sans_700Bold,
            fontSize: 12, // Reduced font size
            color: '#FFFFFF',
            lineHeight: 1.5,
            marginBottom: 0,
            marginLeft: '10px', // Reduced space between buttons and text
            marginRight: '10px',
          }}
        >
          {quantity || 0}
        </div>

        <button
          onClick={(e) => {
            e.stopPropagation();
            dispatch(actions.addToCart(item));
          }}
          style={{
            // backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
            padding: '6px', // Reduced padding
            borderRadius: '50%',
            backgroundColor: theme.colors.mainColor,
          }}
        >
          {renderPlusSvg()}
        </button>
      </div>
    </div>
  );
};
