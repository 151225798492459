import axios from 'axios';
import {useEffect, useState, FC} from 'react';
import {useNavigate} from 'react-router-dom';
import * as Accordion from '@radix-ui/react-accordion';

import {text} from '../text';
import {URLS} from '../config';
import {utils} from '../utils';
import {svg} from '../assets/svg';
import {theme} from '../constants';
import {components} from '../components';
import {Order} from '../types/OrderType';
import {fetchOrders} from '../services/orderService';

export const OrderHistory: FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [ordersData, setOrdersData] = useState<Order[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const getOrders = async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await fetchOrders(10, page); // Adjusted the limit to 10 for better pagination

      // Set the array of orders and total pages
      setOrdersData(response.data.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrders();
  }, [page]);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString(); // Converts to human-readable format
  };

  const getStatusColor = (status: string) => {
    if (status === 'workplace_approved') return '#51BA74'; // Green
    if (status === 'pending') return '#F5C102'; // Orange
    return status === 'delivered'
      ? '#51BA74'
      : status === 'shipping'
      ? '#F5C102'
      : '#FF4343';
  };

  const renderHeader = () => {
    return <components.Header goBack={false} basket={true} />;
  };

  const renderFooter = (): JSX.Element => {
    if (loading) return <components.TabLoader />;

    return (
      <components.Footer
        logo={true}
        copyright={true}
        links={[
          {label: 'Home', path: '/Home'},
          {label: 'About', path: '/About'},
          {label: 'Services', path: '/Services'},
          {label: 'Contact', path: '/Contact'},
        ]}
      />
    );
  };

  const renderContent = (): JSX.Element => {
    if (loading) return <components.Loader />;

    return (
      <main style={{padding: '10px 0 20px 0', marginTop: 180}}>
        <Accordion.Root type='single' collapsible={true}>
          {ordersData.map((order, index) => (
            <Accordion.Item key={index} value={order.id.toString()}>
              <Accordion.Trigger
                style={{
                  flexDirection: 'column',
                  width: '100%',
                  display: 'flex',
                  padding: '20px 20px 14px 20px',
                  borderTop: '1px solid #E7EBEB',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    marginBottom: 10,
                    ...utils.rowCenterSpcBtween(),
                  }}
                >
                  <text.H5 style={{color: theme.colors.mainColor}}>
                    #{order.order_number}
                  </text.H5>
                  <text.H5 style={{color: theme.colors.mainColor}}>
                    €{order.total_price}
                  </text.H5>
                </div>
                <div style={{width: '100%', ...utils.rowCenterSpcBtween()}}>
                  <text.T12
                    style={{
                      textTransform: 'capitalize',
                      color: getStatusColor(order.status),
                    }}
                  >
                    {order.status}
                  </text.T12>
                  <text.T12 style={{color: theme.colors.textColor}}>
                    {formatDate(order.created_at)}
                  </text.T12>
                </div>
              </Accordion.Trigger>
              <Accordion.Content style={{padding: '10px 20px 20px 20px'}}>
                <div
                  style={{
                    backgroundColor: '#FAFAFA',
                    padding: 20,
                    borderRadius: 15,
                    marginBottom: 20,
                  }}
                >
                  {order.products.map((item: any, index: any, array: any) => {
                    const isLast = index === array.length - 1;
                    return (
                      <div
                        key={index}
                        style={{
                          width: '100%',
                          ...utils.rowCenterSpcBtween(),
                          marginBottom: isLast ? 10 : 6,
                        }}
                      >
                        <text.T14 style={{color: theme.colors.textColor}}>
                          {item.product.name}
                        </text.T14>
                        <text.T14 style={{color: theme.colors.textColor}}>
                          {item.quantity} x {item.price}
                        </text.T14>
                      </div>
                    );
                  })}
                  <div
                    style={{
                      height: 1,
                      width: '100%',
                      backgroundColor: '#E7EBEB',
                      marginBottom: 10,
                    }}
                  />
                  <div style={{...utils.rowCenterSpcBtween()}}>
                    <text.T14 style={{color: theme.colors.mainColor}}>
                      Total
                    </text.T14>
                    <text.T14 style={{color: theme.colors.mainColor}}>
                      €{order.total_price}
                    </text.T14>
                  </div>
                </div>
                <div style={{width: '100%', ...utils.rowCenterSpcBtween()}}>
                  <button style={{lineHeight: 0}}>
                    <svg.RepeatOrderSvg />
                  </button>
                  <button
                    style={{lineHeight: 0}}
                    onClick={() => navigate('/LeaveAReview')}
                  >
                    <svg.LeaveAReviewSvg />
                  </button>
                </div>
              </Accordion.Content>
            </Accordion.Item>
          ))}
        </Accordion.Root>
        <div
          style={{
            marginTop: 20,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {page > 1 && (
            <button onClick={() => setPage((prev) => prev - 1)}>
              Previous
            </button>
          )}
          {page < totalPages && (
            <button onClick={() => setPage((prev) => prev + 1)}>Next</button>
          )}
        </div>
      </main>
    );
  };

  return (
    <>
      {renderHeader()}
      {renderContent()}
      {renderFooter()}
    </>
  );
};
