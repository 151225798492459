import React from 'react';

import {utils} from '../utils';
import {theme} from '../constants';
import {ProductType} from '../types';

type Props = {
  item: ProductType;
  containerStyle?: React.CSSProperties;
};

export const ProductPrice: React.FC<Props> = ({containerStyle, item}) => {
  return (
    <div>
      {item.oldPrice && (
        <span
          style={{
            marginRight: 4,
            color: theme.colors.textColor,
            textDecorationLine: 'line-through',
            ...theme.fonts.DM_Sans_400Regular,
            fontSize: 12,
          }}
        >
          €{item.oldPrice}
        </span>
      )}
      <span
        style={{
          fontSize: 14,
          ...theme.fonts.DM_Sans_500Medium,
          color: theme.colors.textColor,
        }}
      >
        €
        {item.default_sales_type === 'PACK'
          ? (Number(item.price_per_pack) * (item.quantity || 1)).toFixed(2)
          : (Number(item.price) * (item.quantity || 1)).toFixed(2)}
      </span>
    </div>
  );
};
