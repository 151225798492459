const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchCategories = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/categories`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch categories');
    }

    const data = await response.json();
    return data.data; // Assuming the 'data' field contains the categories
  } catch (error) {
    console.error('Fetch categories error:', error);
    throw error;
  }
};

export const fetchCategoriesWithProducts = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/homepage`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch categories');
    }

    const data = await response.json();
    return data.data; // Assuming the 'data' field contains the categories
  } catch (error) {
    console.error('Fetch categories error:', error);
    throw error;
  }
};

export const fetchProductsByCategory = async (
  categoryId: number,
  page: number = 1,
  limit: number = 15
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/categories/${categoryId}?page=${page}&limit=${limit}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch products');
    }

    const data = await response.json();
    return data.data.products; // Assuming the 'products' field contains the product list
  } catch (error) {
    console.error('Fetch products error:', error);
    throw error;
  }
};

// New API for fetching related products
export const fetchRelatedProducts = async (productId: number) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/products/${productId}?related=1`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch related products');
    }

    const data = await response.json();
    return data.data.related; // Assuming the 'related' field contains the related products
  } catch (error) {
    console.error('Fetch related products error:', error);
    throw error;
  }
};
export const fetchAllProductsByCategory = async (
  categoryId: number,
  main: number
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/categories/${categoryId}/products?main=1`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch products by category');
    }

    const data = await response.json();
    return data; // assuming the API response structure fits your needs
  } catch (error) {
    console.error('Error fetching products by category:', error);
    throw error;
  }
};
