import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';

import {text} from '../../text';
import {hooks} from '../../hooks';
import {items} from '../../items';
import {URLS} from '../../config';
import {utils} from '../../utils';
import {svg} from '../../assets/svg';
import {theme} from '../../constants';
import {RootState} from '../../store';
import {components} from '../../components';
import {Box, Grid, useTheme, useMediaQuery} from '@mui/material';

export const Profile: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const {phoneVerified, emailVerified} = hooks.useAppSelector(
    (state: RootState) => state.verificationSlice
  );
  const themeone = useTheme();
  const isMobile = useMediaQuery(themeone.breakpoints.down('sm'));
  useEffect(() => {
    const storedProfile = JSON.parse(localStorage.getItem('profile') || '{}');
    setUser(storedProfile);
    setLoading(false);
    window.scrollTo(0, 0);
  }, []);

  const renderHeader = (): JSX.Element => {
    return <components.Header basket={true} line={true} navlink={true} />;
  };

  const renderFooter = (): JSX.Element => {
    return (
      <components.Footer
        logo={true}
        copyright={true}
        links={[
          {label: 'Home', path: '/Home'},
          {label: 'About', path: '/About'},
          {label: 'Services', path: '/Services'},
          {label: 'Contact', path: '/Contact'},
        ]}
      />
    );
  };

  const renderUserDetails = (): JSX.Element => {
    return (
      <div
        style={{
          marginBottom: 30,
          padding: '10px 50px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div>
          <text.H5
            style={{
              marginBottom: 6,
              textTransform: 'capitalize',
              color: theme.colors.mainColor,
            }}
          >
            {user.full_name}
          </text.H5>
          <text.T14>{user.email}</text.T14>
        </div>
      </div>
    );
  };

  const renderMenu = (): JSX.Element => {
    return (
      <div
        style={{
          paddingLeft: 20,
          width: isMobile ? 300 : 700,
          textAlign: 'center',
        }}
      >
        <items.ProfileItem
          title='My orders'
          icon={<svg.ServerSvg />}
          onClick={() => {
            navigate('/OrderHistory');
          }}
          containerStyle={{marginBottom: 10}}
        />
        <items.ProfileItem
          title='Sign out'
          icon={<svg.LogOutSvg />}
          onClick={() => {
            navigate('/SignOut');
          }}
          navIcon={true}
          containerStyle={{marginBottom: 10}}
        />
      </div>
    );
  };

  const renderContent = () => {
    if (loading) return <components.TabLoader />;

    return (
      <main
        style={{
          paddingTop: 40,
          paddingBottom: 64 + 30,
          marginTop: 140,
          flex: 1,
        }}
      >
        {renderUserDetails()}
        {renderMenu()}
      </main>
    );
  };

  const renderBottomTabBar = () => {
    return <components.BottomTabBar />;
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          justifyContent: 'center',
        }}
      >
        {renderHeader()}
        {renderContent()}
        {renderFooter()}
      </div>
    </>
  );
};
