import {FC, useState, useEffect} from 'react';
import {registerUser} from '../services/authService'; // Import the register service
import {text} from '../text';
import {hooks} from '../hooks';
import {utils} from '../utils';
import {custom} from '../custom';
import {svg} from '../assets/svg';
import {theme} from '../constants';
import {components} from '../components';
import {CircularProgress} from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {fetchWorkplaces} from '../services/authService';
export const SignUp: FC = (): JSX.Element => {
  const navigate = hooks.useAppNavigate();
  const [workplaceId, setWorkplaceId] = useState(1); // Assuming a default value for simplicity
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState<Error | null>(null); // Set an initial state
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [workplaces, setWorkplaces] = useState<any[]>([]);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getWorkplaces = async () => {
      try {
        const data = await fetchWorkplaces();
        setWorkplaces(data);
      } catch (error) {
        console.error('Error fetching workplaces:', error);
      }
    };

    getWorkplaces();
  }, []);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  const handleSignUp = async () => {
    if (password !== passwordConfirmation) {
      alert("Passwords don't match");
      return;
    }

    setLoading(true);

    try {
      const response = await registerUser({
        workplace_id: workplaceId,
        first_name: firstName,
        last_name: lastName,
        email,
        password,
        password_confirmation: passwordConfirmation,
      });
      console.log('Registration successful:', response);
      navigate('/SignUpAccountCreated');
    } catch (error) {
      setError(error as Error);
      console.error('Registration failed:', error);

      // alert('Registration failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const renderContent = (): JSX.Element => {
    return (
      <main
        className='container'
        style={{padding: '50px 20px 20px 20px', marginTop: 10}}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 10,
          }}
        >
          <img
            src='https://mariaandsons.com/img/Icons/m&s_lgo.png'
            alt='Logo'
            style={{width: '270px', height: '150px', cursor: 'pointer'}}
            onClick={() => navigate('/SignIn')}
          />
        </div>
        <text.H1
          style={{marginBottom: 40, display: 'flex', justifyContent: 'center'}}
        >
          Sign up
        </text.H1>
        <custom.InputField
          label='First Name'
          placeholder='Olivia'
          icon={<svg.InputCheckSvg />}
          containerStyle={{marginBottom: 20}}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <custom.InputField
          label='Last Name'
          placeholder='Bennett'
          icon={<svg.InputCheckSvg />}
          containerStyle={{marginBottom: 20}}
          onChange={(e) => setLastName(e.target.value)}
        />
        <custom.InputField
          label='Email'
          icon={<svg.InputCheckSvg />}
          placeholder='oliviabennett@mail.com'
          containerStyle={{marginBottom: 20}}
          onChange={(e) => setEmail(e.target.value)}
        />
        <custom.InputField
          label='Password'
          placeholder='Enter Password'
          icon={
            <div onClick={togglePasswordVisibility} style={{cursor: 'pointer'}}>
              {isPasswordVisible ? (
                <VisibilityOutlinedIcon />
              ) : (
                <svg.EyeOffSvg />
              )}
            </div>
          }
          containerStyle={{marginBottom: 20}}
          type={isPasswordVisible ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <custom.InputField
          label='Password'
          placeholder='Confirm Password'
          icon={
            <div onClick={togglePasswordVisibility} style={{cursor: 'pointer'}}>
              {isPasswordVisible ? (
                <VisibilityOutlinedIcon />
              ) : (
                <svg.EyeOffSvg />
              )}
            </div>
          }
          containerStyle={{marginBottom: 20}}
          type={isPasswordVisible ? 'text' : 'password'}
          value={passwordConfirmation}
          onChange={(e) => setPasswordConfirmation(e.target.value)}
        />{' '}
        <form
          className='sign-up-form'
          style={{
            marginBottom: 10,
            height: 50,
            paddingLeft: 30,
            paddingRight: 20,
            borderRadius: 12,
            position: 'relative',
            border: '1px solid #E7EBEB',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <label htmlFor='workplace' style={{marginRight: 20}}>
            Workplace
          </label>
          <select
            id='workplace'
            value={workplaceId || ''}
            className='workplace-select'
            style={{
              width: '70%', // Make the dropdown bigger
              padding: '10px', // Increase padding for a bigger appearance
              fontSize: '16px', // Slightly larger font for readability
              borderRadius: '6px', // Rounded corners
              border: 'none', // Removed the border
              backgroundColor: '#fff', // Set background to white (optional)
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Optional: light shadow for subtle effect
            }}
            onChange={(e) => {
              const selectedId = parseInt(e.target.value, 10);
              if (!isNaN(selectedId)) {
                setWorkplaceId(selectedId);
              }
            }}
          >
            <option value='' disabled>
              Select Workplace
            </option>
            {workplaces.map((workplace) => (
              <option key={workplace.id} value={workplace.id}>
                {workplace.name}
              </option>
            ))}
          </select>
        </form>
        {error && (
          <div style={{color: 'red', textAlign: 'center'}}>{error.message}</div>
        )}
        {loading ? (
          <div style={{textAlign: 'center', marginTop: '20px'}}>
            <CircularProgress color='inherit' />
          </div>
        ) : (
          <components.Button
            title='Sign up'
            onClick={handleSignUp}
            // loading={loading}
            style={{marginBottom: 20}}
          />
        )}
        <div style={{...utils.rowCenter(), marginBottom: 50}}>
          <text.T16 style={{marginRight: 4}}>Already have an account?</text.T16>
          <text.T16
            onClick={() => navigate('/SignIn')}
            style={{color: theme.colors.mainColor, cursor: 'pointer'}}
          >
            Sign in.
          </text.T16>
        </div>
      </main>
    );
  };

  return <>{renderContent()}</>;
};
