import axios from 'axios';
import {useState, useRef} from 'react';
import {useEffect, FC} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import useEmblaCarousel from 'embla-carousel-react';

import {URLS} from '../../config';
import {hooks} from '../../hooks';
import {items} from '../../items';
import {custom} from '../../custom';
import {theme} from '../../constants';
import {actions} from '../../store/actions';
import {components} from '../../components';
import {fetchCategories} from '../../services/categoryService';
import {fetchCategoriesWithProducts} from '../../services/categoryService';
import {ProductType, BannerType, CategoryType} from '../../types';
import {Box, Grid, useTheme, useMediaQuery} from '@mui/material';

export const Home: FC = () => {
  const dispatch = hooks.useAppDispatch();
  const navigate = hooks.useAppNavigate();
  const themeone = useTheme();
  const isMobile = useMediaQuery(themeone.breakpoints.down('sm'));
  const [emblaRef, emblaApi] = useEmblaCarousel();
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);

  const [bannersData, setBannersData] = useState<BannerType[]>([]);
  const [productsData, setProductsData] = useState<ProductType[]>([]);
  // const [carouselData, setCarouselData] = useState<CarouselType[]>([]);
  const [categoriesData, setCategoriesData] = useState<CategoryType[]>([]);
  const [homeCategoriesData, setHomeCategoriesData] = useState<any[]>([]);
  const carouselData = [
    'https://i.postimg.cc/dtVjpWDt/cover2.png',

    'https://i.postimg.cc/cL6TgDN5/cover1.png',

    'https://i.postimg.cc/tJ2t76vj/cover3.png',
    // Add more image URLs as needed
  ];
  const handleSlideChange = () => {
    if (emblaApi) {
      setActiveIndex(emblaApi.selectedScrollSnap());
    }
  };

  const [currentIndex, setCurrentIndex] = useState(0); // Active slide index
  const emblaRefe = useRef(null); // Reference for the carousel container

  // Auto-slide effect
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselData.length); // Cycle through images
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval); // Clean up the interval on unmount
  }, []);

  useEffect(() => {
    const fetchBestSellers = async () => {
      try {
        // Fetch categories and products data
        const data = await fetchCategoriesWithProducts();
        console.log(data.child);
        setHomeCategoriesData(data);
      } catch (error) {
        console.error('Failed to fetch best sellers:', error);
      }
    };

    fetchBestSellers();
  }, []);

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const data = await fetchCategories();
        setCategoriesData(data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      } finally {
        setLoading(false);
      }
    };

    loadCategories();
  }, []);
  useEffect(() => {
    updateProductImages();
    if (emblaApi) {
      emblaApi.on('select', handleSlideChange);
    }
  }, [emblaApi]);
  const updateProductImages = () => {
    const updatedProducts = productsData.map((product) => {
      return {
        ...product,
        image:
          'https://img.freepik.com/premium-photo/3d-render-photos-healthy-food-meal_1096167-99597.jpg?w=1380', // Update the image URL as needed
      };
    });

    setProductsData(updatedProducts);
  };
  const getData = async () => {
    setLoading(true);
    console.log(productsData);

    try {
      const products = await axios
        .get(URLS.GET_PRODUCTS)
        .then((res) => res.data.products);

      const categories = await axios
        .get(URLS.GET_CATEGORIES)
        .then((res) => res.data.categories);

      const banners = await axios
        .get(URLS.GET_BANNERS)
        .then((res) => res.data.banners);

      const carousel = await axios
        .get(URLS.GET_CAROUSEL)
        .then((res) => res.data.carousel);

      setBannersData(banners);
      setProductsData(products);
      // setCarouselData(carousel);
      // setCategoriesData(categories);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);

  const renderIndicator = (): JSX.Element => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          position: 'absolute',
          bottom: 20,
          left: 20,
          flexDirection: 'row',
          gap: 6,
        }}
      >
        {carouselData?.map((imageSrc: string, index: number) => {
          const isSelected = index === activeIndex;
          return (
            <div
              style={{
                background: isSelected
                  ? theme.colors.mainColor
                  : theme.colors.white,
                width: 10,
                height: 10,
                borderRadius: 5,
              }}
              key={index}
            />
          );
        })}
      </div>
    );
  };
  const renderCarouselContent = (): JSX.Element => {
    return (
      <div className='embla' ref={emblaRef}>
        <div className='embla__container' style={{display: 'flex'}}>
          {carouselData.map((imageSrc: string, index: number) => {
            return (
              <div
                key={index}
                className='embla__slide'
                style={{
                  position: 'relative',
                  width: '100%',
                  flex: '0 0 100%',
                  overflow: 'hidden',
                  cursor: 'pointer',
                }}
              >
                <img
                  src={imageSrc}
                  style={{
                    width: '100%',
                    height: isMobile ? '30vh' : '50vh', // Keep the height the same
                    // objectFit: 'contain', // Ensure the entire image fits without cutting off
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderCarousel = () => {
    return (
      <section style={{position: 'relative', marginTop: 100, marginBottom: 20}}>
        {renderCarouselContent()}
        {renderIndicator()}
      </section>
    );
  };

  const renderFooter = (): JSX.Element => {
    if (loading) return <components.TabLoader />;

    return (
      <components.Footer
        logo={true}
        copyright={true}
        links={[
          {label: 'Home', path: '/Home'},
          {label: 'About', path: '/About'},
          {label: 'Services', path: '/Services'},
          {label: 'Contact', path: '/Contact'},
        ]}
      />
    );
  };
  const renderHeader = (): JSX.Element => {
    return (
      <components.Header
        logo={true}
        burger={true}
        basket={true}
        line={true}
        navlink={true}
      />
    );
  };

  const renderCategories = (): JSX.Element => {
    return (
      <div style={{marginBottom: 50}}>
        <Swiper
          spaceBetween={14}
          slidesPerView={'auto'}
          pagination={{clickable: true}}
        >
          {categoriesData.map((item: CategoryType) => (
            <SwiperSlide
              key={item.id}
              style={{
                height: 220,
                width: 220,
                borderRadius: 10,
                cursor: 'pointer',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                padding: '10px 10px 8px 10px',
                justifyContent: 'space-between',
                backgroundColor: theme.colors.imageBackground,
              }}
              onClick={() => {
                // Replace with your navigation logic
                navigate('/shop', {
                  state: {products: item, title: 'Shop'}, // Adjust this as needed
                });
              }}
            >
              <img
                alt={item.name}
                src={item.attachments?.category} // Ensure the API provides this image URL
                style={{
                  objectFit: 'cover',
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  inset: 0,
                  borderRadius: 10,
                  zIndex: -1,
                }}
              />

              {/* Background gradient for better visibility */}
              <div
                style={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: '40%',
                  background:
                    'linear-gradient(transparent, rgba(0, 0, 0, 0.5))',
                  borderRadius: '0 0 10px 10px',
                  zIndex: 0,
                }}
              />

              <span
                style={{
                  ...theme.fonts.DM_Sans_400Regular,
                  fontSize: 8,
                  backgroundColor: theme.colors.pastelMint,
                  alignSelf: 'flex-start',
                  padding: '2px 3px 1px 3px',
                  borderRadius: 50,
                  lineHeight: 1.5,
                }}
              >
                {item.qty} {/* Adjust the qty field to match your API */}
              </span>

              <span
                style={{
                  // ...theme.fonts.DM_Sans_400Regular,
                  fontSize: 12,
                  color: theme.colors.white,
                  lineHeight: 1.5,
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  zIndex: 1, // Ensure it appears above the gradient
                  textShadow: '1px 1px 4px rgba(0, 0, 0, 0.7)', // Add shadow to text
                }}
              >
                {item.name}
              </span>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  };

  const renderBestSellers = (): JSX.Element => {
    return (
      <div style={{marginBottom: 40}}>
        {homeCategoriesData.map((category: any, index: number) => (
          <div
            key={category.id}
            style={{marginBottom: 40, flexDirection: 'column'}}
          >
            <components.BlockHeading
              title={category.name}
              containerStyle={{padding: '0 20px 0', marginBottom: 14}}
              viewAllOnClick={() => {
                dispatch(actions.resetFilters());
                navigate('/ShopHome', {
                  state: {CategoryId: category.id, title: category.name},
                });
              }}
            />
            <custom.ScrollView style={{paddingLeft: 20, paddingRight: 20}}>
              {category.products?.map(
                (product: ProductType, index: number, arra: ProductType[]) => {
                  const isLast = index === arra.length - 1;
                  return (
                    <items.CaregoryProductCard
                      key={product.id}
                      isLast={isLast}
                      item={product}
                      version={1}
                    />
                  );
                }
              )}
            </custom.ScrollView>
            {/* {(index + 1) % 5 === 0 && renderBanner()} */}
          </div>
        ))}
      </div>
    );
  };
  const renderBanner = (): JSX.Element => {
    return (
      <div>
        <button
          onClick={() => {
            dispatch(actions.resetFilters());
            navigate('/Shop', {state: {products: productsData}});
          }}
          style={{width: '100%'}}
        >
          <img
            src={'https://mariaandsons.com/img/cover/cover2.png'}
            alt='Banner'
            style={{
              objectFit: 'cover',
              width: '100%',
              height: '50vh', // Reduce the height to half
              borderTopRightRadius: 5,
              borderBottomRightRadius: 5,
            }}
          />
        </button>
      </div>
    );
  };

  const renderFeatured = (): JSX.Element => {
    return (
      <div style={{marginBottom: 40, display: 'flex', flexDirection: 'column'}}>
        <components.BlockHeading
          title='Featured products'
          containerStyle={{paddingLeft: 20, paddingRight: 20, marginBottom: 14}}
          viewAllOnClick={() => {
            dispatch(actions.resetFilters());
            navigate('/Shop', {
              state: {products: productsData, title: 'Featured'},
            });
          }}
        />
        <custom.ScrollView style={{paddingLeft: 20, paddingRight: 20}}>
          {productsData?.map(
            (item: ProductType, index: number, arra: ProductType[]) => {
              const isLast = index === arra.length - 1;
              return (
                <items.ProductCard
                  key={item.id}
                  isLast={isLast}
                  item={item}
                  version={1}
                />
              );
            }
          )}
        </custom.ScrollView>
      </div>
    );
  };

  const renderContent = () => {
    if (loading) return <components.TabLoader />;

    return (
      <main
        style={{
          paddingBottom: 64,
          display: 'flex',
          flexDirection: 'column',
          marginTop: 52,
        }}
      >
        {renderCarousel()}
        {renderCategories()}
        {renderBestSellers()}
        {/* {renderBestSellers()} */}
        {/* {renderBanner()} */}
      </main>
    );
  };

  const renderBottomTabBar = () => {
    return <components.BottomTabBar />;
  };

  return (
    <>
      {renderHeader()}
      {renderContent()}
      {renderFooter()}
      {/* {renderBottomTabBar()} */}
    </>
  );
};
