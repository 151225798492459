import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {hooks} from '../hooks';
import {items} from '../items';
import {RootState} from '../store';
import {components} from '../components';
import {markNotificationAsRead} from '../services/notificationService';
import {Typography} from '@mui/material';
import {Box, Grid, useTheme, useMediaQuery} from '@mui/material';

export const Notifications: React.FC = () => {
  const location = useLocation();
  const notifications = location.state.notifications;
  const [loading, setLoading] = useState<boolean>(false);
  const themeone = useTheme();
  const isMobile = useMediaQuery(themeone.breakpoints.down('sm'));
  console.log(notifications);
  useEffect(() => {
    window.scrollTo(0, 0);

    // Mark notification as read
    if (notifications) {
      markNotificationAsRead()
        .then(() => {
          console.log('Notification marked as read');
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [notifications]);

  const renderFooter = (): JSX.Element => {
    if (loading) return <components.TabLoader />;

    return (
      <components.Footer
        logo={true}
        copyright={true}
        links={[
          {label: 'Home', path: '/Home'},
          {label: 'About', path: '/About'},
          {label: 'Services', path: '/Services'},
          {label: 'Contact', path: '/Contact'},
        ]}
      />
    );
  };

  const renderContent = () => {
    return (
      <main
        className='container'
        style={{
          justifyContent: 'center',
          marginBottom: 20,
          minWidth: isMobile ? '' : 900,
          maxWidth: isMobile ? 750 : '',
          paddingTop: 20,
          paddingBottom: 64 + 20,
          marginTop: 100,
          backgroundColor: 'whitesmoke',
        }}
      >
        {notifications.length > 0 ? (
          notifications.map((notification: any, index: number) => {
            const isLast = index === notifications.length - 1;
            return (
              <items.NotificationItem
                key={notification.id}
                notification={notification}
                isLast={isLast}
              />
            );
          })
        ) : (
          <Typography textAlign={'center'} width={isMobile ? 300 : 900}>
            No Notifications Yet
          </Typography>
        )}
      </main>
    );
  };

  const renderHeader = () => {
    return (
      <components.Header
        title=''
        burger={true}
        basket={true}
        line={true}
        goBack={false}
      />
    );
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          justifyContent: 'center',
        }}
      >
        {renderHeader()}
        {renderContent()}
        {renderFooter()}
        {/* Optional: renderBottomTabBar if needed */}
      </div>
    </>
  );
};
