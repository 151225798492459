import React from 'react';

import {hooks} from '../hooks';
import {custom} from '../custom';
import {product} from '../product';
import {theme} from '../constants';
import {ProductType} from '../types';

type Props = {item: ProductType; isLast: boolean};

export const OrderItem: React.FC<Props> = ({item, isLast}) => {
  const navigate = hooks.useAppNavigate();

  const renderImage = (): JSX.Element => {
    return (
      <button
        onClick={() => navigate('/product', {state: {item}})}
        style={{borderRadius: 12}}
      >
        <custom.ImageBackground
          imageUrl={item.attachments.product_main}
          style={{
            width: 100,
            height: '100%',
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
            backgroundColor: theme.colors.imageBackground,
            objectFit: 'cover',
          }}
        >
          <product.ProductSale
            item={item}
            style={{margin: 10, position: 'absolute', bottom: 0, left: 0}}
          />
        </custom.ImageBackground>
      </button>
    );
  };

  const renderInfo = (): JSX.Element => {
    return (
      <div
        style={{
          borderColor: theme.colors.antiFlashWhite,
          width: '100%',
          paddingRight: 0,
          flexDirection: 'row',
          flex: 1,
          display: 'flex',
          // border: '1px solid #E7EBEB',
          borderLeft: 0,
          borderRight: 0,
        }}
      >
        <div
          style={{
            marginRight: 'auto',
            display: 'flex',
            flexDirection: 'column',
            padding: '14px 0px 14px 14px',
          }}
        >
          <product.ProductName item={item} />
          <product.ProductPrice
            item={item}
            containerStyle={{marginBottom: 'auto'}}
          />
          {/* <span
            style={{
              fontSize: 12,
              color: theme.colors.textColor,
              lineHeight: 1.5,
              ...theme.fonts.DM_Sans_400Regular,
            }}
          >
            Color: {item.color ? item.color : 'No color'}
          </span> */}
        </div>
        <product.ProductCounter item={item} />
      </div>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: 100,
        padding: '0 0 0 20px',
        marginBottom: isLast ? 20 : 14,
      }}
    >
      {renderImage()}
      {renderInfo()}
    </div>
  );
};
