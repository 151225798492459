import React, {useEffect, useState} from 'react';

import {text} from '../../text';
import {utils} from '../../utils';
import {items} from '../../items';
import {custom} from '../../custom';
import {svg} from '../../assets/svg';
import {theme} from '../../constants';
import {components} from '../../components';
import {hooks, RootState} from '../../hooks';
import {createOrder} from '../../services/orderService';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from '@mui/material';
import {keyframes} from '@emotion/react';
import Paper from '@mui/material/Paper';
import ConfettiExplosion from 'react-confetti-explosion';
import {actions} from '../../store/actions';

export const Order: React.FC = () => {
  const dispatch = hooks.useAppDispatch();
  const navigate = hooks.useAppNavigate();
  const [open, setOpen] = useState(false);
  const [isExploding, setIsExploding] = React.useState(false);
  const [loading, setIsLoading] = React.useState(false);
  const isSignedIn = Boolean(sessionStorage.getItem('authToken')); // Or use a state from Redux

  const handleOrderSuccess = () => {
    // Show modal when order is successful
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const {subtotal, discount, list, total, discountAmount, totalWeight} =
    hooks.useAppSelector((state: RootState) => state.cartSlice);
  console.log(list);
  useEffect(() => {
    if (!isSignedIn) {
      navigate('/SignIn');
    }
    window.scrollTo(0, 0);
  }, []);

  console.log(list);

  const OrderSuccessModal: React.FC<{open: boolean; onClose: () => void}> = ({
    open,
    onClose,
  }) => {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby='success-dialog-title'
        maxWidth='xs' // Make the modal smaller
        fullWidth
        PaperProps={{
          style: {
            borderRadius: '20px', // Rounded corners
            padding: '20px', // Padding for better spacing
            textAlign: 'center', // Center content
          },
        }}
      >
        <img
          src='https://mariaandsons.com/img/Icons/m&s_lgo.png'
          alt='Logo'
          style={{
            width: '120px',
            height: '72px',
            marginBottom: '16px', // Space below the image
            cursor: 'pointer',
          }}
          onClick={() => navigate('/Home')}
        />
        <DialogTitle id='success-dialog-title'>Order Successful!</DialogTitle>
        <DialogTitle id='success-dialog-title'>
          Wait for further Instructions!
        </DialogTitle>

        <DialogContent>
          <div
            style={{
              position: 'relative',
              overflow: 'hidden',
              height: '10px',
              width: '100%',
            }}
          >
            {isExploding && <ConfettiExplosion />}
          </div>
          <div style={{textAlign: 'center', marginTop: '20px'}}>
            <components.Button
              title='Close'
              colorScheme='light'
              onClick={onClose}
              containerStyle={{padding: '0 20px'}}
            />
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  const renderHeader = (): JSX.Element | null => {
    return (
      <components.Header
        // title='Order'
        // burger={true}
        basket={true}
        line={true}
        // goBack={true}
        navlink={true}
      />
    );
  };

  const renderProducts = (): JSX.Element | null => {
    if (list.length > 0) {
      return (
        <div
          style={{
            marginBottom: 30,
            borderBottom: `1px solid ${theme.colors.lavenderMist}`,
          }}
        >
          {list?.map((item, index, array) => {
            const isLast = index === array.length - 1;
            return <items.OrderItem key={index} item={item} isLast={isLast} />;
          })}
        </div>
      );
    }

    return null;
  };

  const renderAppliedText = (): JSX.Element | null => {
    if (discount > 0) {
      return (
        <div style={{marginBottom: 30, padding: '0 20px'}}>
          <svg.PromocodeAppliedSvg />
        </div>
      );
    }

    return null;
  };

  const renderApplyPromocode = (): JSX.Element | null => {
    if (discount > 0) return null;

    return (
      <div
        style={{
          marginBottom: 60,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 20px',
        }}
      >
        {/* <custom.InputField
          label='Enter the voucher'
          containerStyle={{width: 'calc(70% - 5px)'}}
          placeholder={'Enter the promocode'}
        /> */}
        {/* <components.Button
          title='Apply'
          containerStyle={{padding: 0, width: 'calc(30% - 5px)'}}
          // onClick={() => {
          //   dispatch(actions.setDiscount(20));
          // }}
        /> */}
      </div>
    );
  };

  const renderTotal = (): JSX.Element | null => {
    if (list.length > 0) {
      return (
        <div
          style={{
            padding: 20,
            borderRadius: 15,
            backgroundColor: '#FAFAFA',
            margin: '0px 20px 20px 20px',
          }}
        >
          <div style={{...utils.rowCenterSpcBtween(), marginBottom: 10}}>
            <text.H5 style={{color: theme.colors.mainColor}}>
              Total Weight
            </text.H5>
            <text.T16 style={{color: theme.colors.mainColor}}>
              {totalWeight} kg
            </text.T16>
          </div>
          <div style={{...utils.rowCenterSpcBtween(), marginBottom: 10}}>
            <text.H5 style={{color: theme.colors.mainColor}}>Subtotal</text.H5>
            <text.T16 style={{color: theme.colors.mainColor}}>
              €{subtotal.toFixed(2).replace('.', '.')}
            </text.T16>
          </div>

          {discount > 0 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 10,
              }}
            >
              <h5
                style={{
                  margin: 0,
                  ...theme.fonts.Inter_600SemiBold,
                  fontSize: 16,
                  color: theme.colors.textColor,
                  lineHeight: 1.5,
                }}
              >
                Discount
              </h5>
              <span
                style={{
                  margin: 0,
                  ...theme.fonts.Inter_600SemiBold,
                  fontSize: 16,
                  color: theme.colors.textColor,
                  lineHeight: 1.5,
                }}
              >
                - {discountAmount}
              </span>
            </div>
          )}
          <div
            style={{
              marginBottom: 10,
              paddingBottom: 10,
              ...utils.rowCenterSpcBtween(),
              borderBottom: `1px solid ${theme.colors.lavenderMist}`,
            }}
          >
            <text.T14 style={{color: theme.colors.textColor}}>
              Estimated Shipping Cost (Excluding Taxes and Local Customs
              Storage)
            </text.T14>
            <text.T14 style={{color: theme.colors.textColor}}>
              €{(totalWeight * 7.5).toFixed(2)}
            </text.T14>
          </div>
          <div style={{...utils.rowCenterSpcBtween()}}>
            <text.H4 style={{color: theme.colors.mainColor}}>Total</text.H4>
            <text.H4 style={{color: theme.colors.mainColor}}>
              €{total.toFixed(2).replace('.', '.')}
            </text.H4>
          </div>
        </div>
      );
    }

    return null;
  };

  const renderButton = (): JSX.Element | null => {
    const handleCreateOrder = async () => {
      setIsExploding(false);
      setIsLoading(true);
      const orderData = {
        products: list.map((item) => ({
          id: item.id,
          quantity: item.quantity ?? 1,
          is_pack: item.default_sales_type === 'PACK' ? true : false,
        })),
      };

      try {
        const response = await createOrder(orderData);
        if (response.success) {
          setIsExploding(true);
          setIsLoading(false);

          handleOrderSuccess();
          dispatch(actions.resetCart());
        }
      } catch (error) {
        console.error('Failed to create order:', error);
      }
    };

    return (
      <>
        {loading ? (
          <div style={{textAlign: 'center', marginTop: '20px'}}>
            <CircularProgress color='inherit' />
          </div>
        ) : (
          <components.Button
            title='Create Order'
            colorScheme='light'
            onClick={handleCreateOrder}
            containerStyle={{padding: '0 20px'}}
          />
        )}
      </>
    );
  };

  const renderContent = (): JSX.Element | null => {
    return (
      <main
        className='container'
        style={{paddingTop: 20, paddingBottom: 64 + 20, marginTop: 160}}
      >
        <div>
          <div>
            <div style={{maxHeight: '500px', overflowY: 'auto'}}>
              {renderProducts()}
              {renderAppliedText()}
            </div>
          </div>
          {renderTotal()}

          {renderButton()}
        </div>
      </main>
    );
  };

  const renderEmptyContent = (): JSX.Element => {
    return (
      <main
        className='container'
        style={{
          padding: '0px 20px 94px 20px',
          marginTop: 52,
          paddingTop: '18vh',
        }}
      >
        <img
          src={require('../../assets/icons/18.png')}
          alt='order successful'
          style={{
            width: 123.39,
            marginBottom: 4,
          }}
        />
        <text.H2
          style={{
            marginBottom: 14,
            textTransform: 'capitalize',
            color: theme.colors.mainColor,
          }}
        >
          Your Cart is empty!
        </text.H2>
        <text.T16
          style={{
            whiteSpace: 'pre-line',
            color: theme.colors.textColor,
            marginBottom: 30,
          }}
        >
          Looks like you haven't chosen the{'\n'}items you like.
        </text.T16>
        <components.Button
          title='Browse products'
          colorScheme='light'
          onClick={() => {
            dispatch(actions.resetFilters());
            navigate('/Home');
          }}
          containerStyle={{marginBottom: 10}}
        />
      </main>
    );
  };

  const renderBottomTabBar = (): JSX.Element | null => {
    return <components.BottomTabBar />;
  };
  const renderFooter = (): JSX.Element => {
    return (
      <components.Footer
        logo={true}
        copyright={true}
        links={[
          {label: 'Home', path: '/Home'},
          {label: 'About', path: '/About'},
          {label: 'Services', path: '/Services'},
          {label: 'Contact', path: '/Contact'},
        ]}
      />
    );
  };

  return (
    <>
      <div style={{minHeight: '100vh'}}>
        {renderHeader()}
        <OrderSuccessModal open={open} onClose={handleClose} />
        {list.length > 0 ? renderContent() : renderEmptyContent()}
        {renderFooter()}
        {/* {renderBottomTabBar()} */}
      </div>
    </>
  );
};
