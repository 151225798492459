import React from 'react';

import {text} from '../text';
import {hooks} from '../hooks';
import {theme} from '../constants';
import {components} from '../components';

export const SignUpAccountCreated: React.FC = () => {
  const navigate = hooks.useAppNavigate();

  const renderContent = (): JSX.Element => {
    return (
      <main
        className='container'
        style={{
          padding: '52px 20px 20px 20px',
          height: 'calc(100vh - 80px - 20px)',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <img
          src={require('../assets/icons/25.png')}
          alt='order successful'
          style={{
            width: 123.39,
            marginBottom: 14,
          }}
        />
        <text.H2
          style={{
            color: theme.colors.mainColor,
            marginBottom: 14,
            textTransform: 'capitalize',
            whiteSpace: 'pre-line',
          }}
        >
          Account Created!
        </text.H2>
        <text.T16
          style={{
            color: theme.colors.textColor,
            marginBottom: 30,
            whiteSpace: 'pre-line',
          }}
        >
          Your account had beed created {'\n'} successfully.
        </text.T16>
        <components.Button
          title='Login'
          onClick={() => {
            navigate('/SignIn');
          }}
        />
      </main>
    );
  };

  return renderContent();
};
