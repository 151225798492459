import React from 'react';

import {hooks} from '../hooks';
import {theme} from '../constants';
import {ProductType} from '../types';
import {actions} from '../store/actions';

type Props = {item: ProductType};

const renderMinusSvg = (): JSX.Element => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={10} height={10} fill='none'>
      <path
        stroke='#4A6973'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.2}
        d='M2.157 5H7.99'
      />
    </svg>
  );
};

const renderPlusSvg = (): JSX.Element => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={10} height={10} fill='none'>
      <path
        stroke='#4A6973'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.2}
        d='M5 2.083v5.834M2.083 5h5.834'
      />
    </svg>
  );
};

export const ProductCounter: React.FC<Props> = ({item}): JSX.Element => {
  const dispatch = hooks.useAppDispatch();
  const cart = hooks.useAppSelector((state) => state.cartSlice.list);

  const quantityInCart = (item: ProductType, cart: ProductType[]): number => {
    const ifItemInCart = cart.find((el) => el.id === item.id);
    const quantity =
      ifItemInCart && ifItemInCart.quantity ? ifItemInCart.quantity : 0;
    return quantity;
  };

  const quantity = quantityInCart(item, cart);
  const cumulativeWeight =
    item.unit_per_pack && (item.weight || item.weight_per_pack)
      ? (item.default_sales_type === 'PACK'
          ? item.weight_per_pack * quantity
          : item.weight * quantity
        ).toFixed(2)
      : 0;
  const addToCartButton = (): JSX.Element => {
    return (
      <button
        style={{padding: '13px 20px 4px 20px', backgroundColor: 'transparent'}}
        onClick={() => {
          dispatch(actions.addToCart(item));
        }}
      >
        {renderPlusSvg()}
      </button>
    );
  };

  const removeFromCartButton = (): JSX.Element => {
    return (
      <button
        style={{padding: '4px 20px 13px 20px', backgroundColor: 'transparent'}}
        onClick={() => {
          dispatch(actions.removeFromCart(item));
        }}
      >
        {renderMinusSvg()}
      </button>
    );
  };

  const renderQty = (): JSX.Element => {
    return (
      <span
        style={{
          ...theme.fonts.DM_Sans_400Regular,
          fontSize: 12,
          color: theme.colors.textColor,
        }}
      >
        {item.quantity}
      </span>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
      }}
    >
      {addToCartButton()}
      {renderQty()}
      {removeFromCartButton()}
      <div
        style={{
          textAlign: 'start',
          justifyContent: 'start',
          whiteSpace: 'nowrap',
          fontSize: 14,
          marginRight: 4,

          color: theme.colors.steelTeal,
        }}
      >
        {`Weight ${quantity} item(s): ${cumulativeWeight} kg`}
      </div>{' '}
    </div>
  );
};
